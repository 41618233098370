<template>
  <div>
    <v-dialog v-model='isUpdateShopStatus' persistent max-width='400'>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color='error' class='me-2'>{{ mdiAlert }}</v-icon>
          ยืนยันลบโปรโมชั่น
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='error' @click='updateStatusShop' :loading='loading' :disabled='loading'>
            ยืนยัน
          </v-btn>
          <v-btn color='secondary' outlined @click='$emit(`update:isUpdateShopStatus`,false)'>
            ยกเลิก
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {api} from '@/services/api'
import {mdiAlert} from '@mdi/js'
import {ref} from '@vue/composition-api'
import store from '@/store'

export default {
  model: {
    prop: 'isUpdateShopStatus',
    event: 'update:isUpdateShopStatus',
  },
  props: {
    isUpdateShopStatus: {
      type: Boolean,
      default: false,
    },
    dataUpdate: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, {root, emit}) {
    const loading = ref(false)

    const updateStatusShop = () => {
      loading.value = true
      api.delete({
        path: '/admin/newsPromotion',
        param: props.dataUpdate.news_promotion_id,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isUpdateShopStatus', false)
          emit('onUpdate')
        }
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.log('err : ', errpr)
      })
    }

    return {updateStatusShop, mdiAlert, loading}
  },

}
</script>

<style scoped>

</style>
