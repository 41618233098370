import {ref, onMounted} from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import {api} from '@/services/api'

export default function usePromotion() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'news_promotion_id',
            width: '50',
        },
        {
            text: 'ชื่อ',
            value: 'news_promotion_title',
            width: '200',
        },
        {
            text: 'รายละเอียด',
            value: 'news_promotion_detail',
            width: '200',
        },
        {
            text: 'วันที่เริ่ม',
            value: 'news_promotion_start',
            width: '150',
        },
        {
            text: 'สิ้นสุด',
            value: 'news_promotion_end',
            width: '150',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '100',
            align: 'end fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isDelete = ref(false)

    const getPromotions = () => {
        loading.value = true
        api.get({
            path: '/admin/newsPromotions',
            param: `?search=${textSearch.value}`,
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getPromotions()
    })

    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isEdit,
        dataEdit,
        footer,
        dataList,
        isDelete,
        loading,
        getPromotions,
    }
}
