<template>
  <div>
    <v-dialog v-model='isAddNewPromotion' persistent max-width='800'>
      <v-card>
        <v-card-title>
          <span>เพิ่มโปรโมชั่น</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewPromotion' @submit.prevent='createPromotion'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12' md="6">
              <v-text-field filled v-model='news_name' dense label='ชื่อ'
                            :rules='[required]'
                            placeholder='ชื่อ' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md="6">
              <v-text-field filled v-model='news_link' dense label='ลิงค์'
                            :rules='[required]'
                            placeholder='ลิงค์' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md="6">
              <v-dialog
                  ref="date1"
                  v-model.trim="isShowDateStart"
                  :return-value.sync="dateStart"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateStart"
                      filled
                      dense
                      :rules="[required]"
                      :prepend-inner-icon="mdiCalendar"
                      readonly
                      label="วันที่ชำระ"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model.trim="dateStart"
                    locale="th"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.date1.save(dateStart)"
                  >
                    ยืนยัน
                  </v-btn>
                  <v-btn
                      text
                      color="secondary"
                      @click="isShowDateStart = false"
                  >
                    ยกเลิก
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols='12' md="6">
              <v-dialog
                  ref="date2"
                  v-model.trim="isShowDateEnd"
                  :return-value.sync="dateEnd"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateEnd"
                      filled
                      dense
                      :rules="[required]"
                      :prepend-inner-icon="mdiCalendar"
                      readonly
                      label="วันที่ชำระ"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model.trim="dateEnd"
                    locale="th"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.date2.save(dateEnd)"
                  >
                    ยืนยัน
                  </v-btn>
                  <v-btn
                      text
                      color="secondary"
                      @click="isShowDateEnd = false"
                  >
                    ยกเลิก
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols='12'>
              <v-textarea filled v-model='news_detail' dense label='ข้อมูล'
                          placeholder='ข้อมูล' hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary'
                   type='submit'
                   :loading='loading'
                   :disabled='loading'>บันทึก
            </v-btn>
            <v-btn color=' secondary
            ' outlined @click='$emit(`update:isAddNewPromotion`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@core/utils/validation'
import {api} from '@/services/api'
import {mdiCloudUploadOutline, mdiPencilOutline, mdiDeleteOutline, mdiPlus, mdiCalendar} from '@mdi/js'
import store from '@/store'
import moment from "moment/moment";

export default {
  model: {
    prop: 'isAddNewPromotion',
    event: 'update:isAddNewPromotion',
  },
  props: {
    isAddNewPromotion: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {root, emit}) {
    const moment = require('moment')
    const news_name = ref('')
    const news_detail = ref('')
    const news_link = ref('')
    const slider_image = ref([])
    const blob_image = ref(null)
    const formAddNewPromotion = ref(null)
    const loading = ref(false)
    const isShowDateStart = ref(false)
    const isShowDateEnd = ref(false)
    const dateStart = ref(moment(new Date()).format('YYYY-MM-DD'))
    const dateEnd = ref(moment(new Date()).format('YYYY-MM-DD'))

    const createPromotion = () => {
      const isFormValid = formAddNewPromotion.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('news_promotion_title', news_name.value)
      formData.append('news_promotion_detail', news_detail.value)
      formData.append('news_promotion_start', dateStart.value)
      formData.append('news_promotion_end', dateEnd.value)
      formData.append('news_promotion_link', news_link.value)
      api.post({
        path: '/admin/newsPromotion',
        body: formData,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewPromotion', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const previewFiles = event => {
      slider_image.value = []
      slider_image.value.push(event.target.files[event.target.files.length - 1])
      blob_image.value = URL.createObjectURL(
          event.target.files[event.target.files.length - 1],
      )
    }

    watch(() => props.isAddNewPromotion, async value => {
      if (value) {
        clearData()
      }
    })

    const clearData = () => {
      slider_image.value = []
      news_name.value = ''
      news_detail.value = ''
      blob_image.value = null
    }

    return {
      news_name,
      news_detail,
      news_link,
      formAddNewPromotion,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiCloudUploadOutline,
      mdiPlus,
      mdiCalendar,
      blob_image,
      slider_image,
      loading,
      isShowDateStart,
      isShowDateEnd,
      dateStart,
      dateEnd,
      previewFiles,
      createPromotion,
      required,
    }
  },

}
</script>

<style scoped>
.set-block {
  max-width: 100%;
  max-height: 200px;
  border: 1px dashed #212121;
  border-radius: 10px;
  background-color: #d6d6d6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
