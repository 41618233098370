<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>
          ข้อมูลโปรโมชั่น
        </span>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAdd = true'>เพิ่มโปรโมชั่น</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getPromotions'
                        label='ค้นหาโปรโมชั่น'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.news_promotion_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.news_promotion_start`]='{ item }'>
          {{ item.news_promotion_start | sumdatetime }}
        </template>
        <template #[`item.news_promotion_end`]='{ item }'>
          {{ item.news_promotion_end | sumdatetime }}
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  :disabled='item.shop_id === 1'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isDelete = true'
                  v-on='on'
              >
                <v-icon>{{ mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ `ลบ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewPromotion v-model='isAdd' @onAdd='getPromotions'/>
    <EditPromotion v-model='isEdit' :dataEdit='dataEdit' @onUpdate='getPromotions'/>
    <DeletePromotion v-model='isDelete' :data-update='dataEdit' @onUpdate='getPromotions'/>
  </div>
</template>

<script>
import {mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiTextBoxCheck} from '@mdi/js'
import AddNewPromotion from './AddNewPromotion.vue'
import EditPromotion from './EditPromotion.vue'
import DeletePromotion from './DeletePromotion.vue'
import usePromotion from "@/views/administrator/promotions/usePromotion";
import {sumdatetime} from "@/services/filters";

export default {
  filters: {
    sumdatetime
  },
  props: {},
  components: {
    EditPromotion,
    DeletePromotion,
    AddNewPromotion,
  },
  setup(_, {root, emit}) {
    return {
      ...usePromotion(),
      mdiCheck,
      mdiTextBoxCheck,
      mdiPencilOutline,
      mdiDeleteOutline,
    }
  },

}
</script>

<style scoped>

</style>
